import { useState } from 'react';
import { Tabs, Progress, Badge, Skeleton, Tooltip, Select } from 'antd';
import { QuestionCircleFilled, CaretDownOutlined } from '@ant-design/icons';
import { useHistory } from "react-router";
import Countdown from 'react-countdown';
import numeral from 'numeral';
import MiningSearch from 'components/MiningSearch';
import { useMiningPoolList } from 'views/hooks';
import formatBytes from 'utils/formatBytes';
import formatFIL from 'utils/formatFIL';
import PoolActive from 'assets/img/pool-active.png'
import PoolClosed from 'assets/img/pool-closed.png'
import FIL from 'assets/img/fil.png'
import {ReactComponent as NoData} from 'assets/img/nodata.svg'
import {ReactComponent as JoinIcon} from 'assets/img/Join.svg'


import './index.less'

const { TabPane } = Tabs;
const { Option } = Select;

export const MiningPoolStatus: any = {
    0:'Open', 
    1:'Locked',
    2:'Mining', 
    3:'Closed'
}

export const MiningPoolBadgeColor: any = {
    0:'#40BA5B', 
    1:'#FFB23F',
    2:'#0090FF', 
    3:'#9A9A9A'
}

const CountdownRender = ({days, hours, minutes, seconds, completed}: any) => {
    if (completed) {
        // Render a completed state
        return <span>--</span>;
      } else {
        // Render a countdown
        return <span>{days}d {hours < 10 ? `0${hours}`: hours}:{minutes < 10 ? `0${minutes}`: minutes}:{seconds < 10 ? `0${seconds}`: seconds}</span>;
      }
}

const MiningPoolItem = ({data}: any) => {
    const history = useHistory();
    return <div className="content" onClick={() => {
        history.push(`miningpool/${data.productId}`)
    }}>
       <div className={`descriptions ${data.status === 0 ? 'open' : 'mining'}`}>
            <div>
                <img src={data.status === 3 ? PoolClosed : PoolActive} alt="" />
            </div>
            <div>
                <div>Product</div>
                <div className='productName'>{data.productName}</div>
            </div>  
            <div>
                <div>
                    <Tooltip placement="right" title="*Intelligent Analysis Based On Economic Model">
                    Est. APY&nbsp;<QuestionCircleFilled/>
                    </Tooltip>
                </div>
                <div>{(data.apy * 100).toFixed(2)}%</div>
            </div>
            <div>
                <div>{data.status > 1 ? 'Amount' : 'Target Amount'}</div>
                <div>
                    <img className="fil" src={FIL} />{formatFIL(data.poolCapacity)}
                </div>
            </div>
            {data.status > 1 ?
                <>  
                    <div>
                        <div>Mining Days</div>
                        <div>
                            {data.minedDays}
                        </div>
                    </div>
                    <div>
                        <div>Adj. Power</div>
                        <div>{formatBytes(data.powerAdj)}</div>
                    </div>
                </>
                :
                <>
                    <div>
                        <div>Progress</div>
                        <div className="progress">
                            <Progress 
                                percent={data.progress * 100} 
                                format={percent => `${numeral(percent).format('0.00')} %`}
                            />
                        </div>
                    </div>
                    <div>
                        <div>Closing Time</div>
                        <div>
                            {data.lockTime && 
                                <Countdown
                                    date={new Date(data.lockTime).getTime()}
                                    renderer={CountdownRender}
                                />
                            }  
                        </div>
                    </div>
                </>
            }  
            <div className="status">
                <div>Status</div>
                <div>
                    <Badge color={MiningPoolBadgeColor[3]}/>
                    {MiningPoolStatus[3]}
                </div>
            </div>  
        </div>
        {data.status === 0 && <div className='join'><JoinIcon/>Join Now! Enjoy an early bird bonus <span>&nbsp;- Extra 20% APY!</span></div>}
    </div>
}

export default function MiningPool() {
    const allList = useMiningPoolList();
    const [status, setStatus] = useState('all')
    const list = allList.filter((v: any) => {return status === 'all' ? true : v.status === status})
    const handleStatusChange = (value: string) => {
        setStatus(value)
    }
    return (
        <div className="mining-pool">
            <div className="mining-search">
                <div className="content">
                    <div>Joint Mining</div>
                    <div>
                        <Select defaultValue={status} style={{width: '120px'}} suffixIcon={<CaretDownOutlined/>} onChange={handleStatusChange}>
                            <Option key="all" value="all">
                                All
                            </Option>
                            <Option key='0' value={0}>
                                Open
                            </Option>
                            <Option key='2' value={2}>
                                Mining
                            </Option>
                        </Select>
                    </div>
                </div>
            </div>
            <div className="container">
                {/* <Tabs defaultActiveKey="all">
                    <TabPane tab="All" key="all"> */}
                        {list.map((data: any, i: number) => Object.keys(data).length > 0 ? <MiningPoolItem key={data.minerId} data={data}/> : <Skeleton key={i} paragraph={{ rows: 2 }} active/>)}
                        {list.length === 0 && 
                            <div className="nodata">
                                <NoData/>
                                <div>No data</div>
                            </div>
                        }
                    {/* </TabPane>
                    <TabPane tab="Open" key="0">
                        {opendList.map((data: any, i: number) => Object.keys(data).length > 0 ? <MiningPoolItem key={data.minerId} data={data}/> : <Skeleton key={i} active/>)}
                        {opendList.length === 0 && 
                            <div className="nodata">
                                <NoData/>
                                <div>No data</div>
                            </div>
                        }
                    </TabPane>
                    <TabPane tab="Mining" key="2">
                        {miningList.map((data: any, i: number) => Object.keys(data).length > 0 ? <MiningPoolItem key={data.minerId} data={data}/> : <Skeleton key={i} active/>)}
                        {miningList.length === 0 && 
                            <div className="nodata">
                                <NoData/>
                                <div>No data</div>
                            </div>
                        }
                    </TabPane>
                </Tabs> */}
            </div>
        </div>
    )
}
