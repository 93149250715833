import { useCallback, useEffect, useState } from 'react';
import { Tooltip as AnTooltip, Badge, Button } from 'antd';
import { QuestionCircleFilled, RightOutlined } from '@ant-design/icons';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { RouteComponentProps } from 'react-router-dom'
import moment from 'moment';
import numeral from 'numeral';
import { useHistory } from "react-router";
import { useMinerDetail, usePowerAndEarningChangeData, submitWithdrawalRequest, useMinerMaintenance } from 'views/hooks';
import useActiveWeb3React from "hooks/useActiveWeb3React";
import MiningSearch from 'components/MiningSearch';
import { cidStartAndEnd } from 'components/Cid'
import formatFIL from 'utils/formatFIL';
import formatBytes from 'utils/formatBytes';
import WithdrawModal from './WithdrawModal';
import {ReactComponent as Change} from '../../assets/img/change.svg'
import {ReactComponent as TableIcon} from '../../assets/img/table.svg'
import {ReactComponent as Product} from '../../assets/img/product.svg'
import {ReactComponent as NoData} from 'assets/img/nodata.svg'
import {ReactComponent as WarningIcon} from 'assets/img/warning.svg'
import {ReactComponent as MaintenanceIcon} from 'assets/img/maintenance.svg'
import Oops from 'assets/img/Oops.png'

import './index.less'

const Miner = (props: RouteComponentProps<{ address: string }>) => {
    const {
      match: {
        params: { address }
      }
    } = props
    const history = useHistory();
    const { account, library } = useActiveWeb3React()
    const [refresh, setRefresh] = useState<number>(1);
    const [withdrawLoading, setWithdrawLoading] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const maintenance = useMinerMaintenance()

    const wtihDraw = useCallback(async() => {
        setWithdrawLoading(true)
        const res: any = await submitWithdrawalRequest(library, account);
        if(res && res.code === '200'){
            setRefresh(refresh + 1)
            setWithdrawLoading(false);
        }else{
            setWithdrawLoading(false)
            setRefresh(refresh + 1)
        }
        setIsModalOpen(false);
    },[library, account, refresh])

    return (
        <div className="miner-detail">
            <MiningSearch address={address}/>
            <div className="container">
                    <div className="no-miner content">
                        <img src={Oops} alt="" />
                        <p>No mining information found for</p>
                        <p>"{address}"</p>
                        <p><a href="mailto:contact@sao.network">if you need help, Click here.</a></p>   
                    </div>
            </div>
        </div>
    )
}

export default Miner
