import { useEffect, useState, useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'
import { useWalletModalToggle } from 'state/application/hooks'
import DiscordWeb3Status from 'components/DiscordWeb3Status'
import { useFilAddress } from 'state/account/hooks';
import { get } from 'utils/request';
import { getSign, clearLocalStorage } from 'utils/txSign';
import { API_URL } from 'constants/index';
import DiscordLogo from 'assets/img/discord_logo.svg'

import './index.less'


const Discord = (props: RouteComponentProps<{ discordId: string }>) => {
    const {
      match: {
        params: { discordId }
      }
    } = props;
    const toggleWalletModal = useWalletModalToggle()
    const { account, library } = useWeb3React()
    const address = useFilAddress();
    const [role, setRole] = useState('')
    const fetchData = useCallback(async() => {
        const sign = await getSign(library, account, false)
        get(`${API_URL}/sao/api/userDetail/getUserDetail?address=${address}$`, sign).then((res: any) => {
            if(res && res.data){
                if (res.data.joinedProductList.length > 0) {
                    setRole(res.data.joinedProductList[0].productName)
                }
            }
        })
    }, [address]);

    useEffect(() => {
        if (address) {
            fetchData()
        }
    
    }, [address]);

    const verifyRole = useCallback(async() => {
        const sign = await getSign(library, account, false)
        get(`${API_URL}/sao/api/userDetail/verifyUser?discordUserId=${discordId}`, sign).then((res: any) => {
            console.log(res)
        })
    }, [role])

    useEffect(() => {
        if (role) {
            verifyRole()
        }
    } , [role])

    return (
        <div className="discord">
                <img className="logo" src={DiscordLogo} />
                <DiscordWeb3Status role={role}/>
        </div>
    )
}

export default Discord
