import React, {StrictMode, Suspense, useEffect } from 'react'
import { HashRouter, Route, Switch } from "react-router-dom";
import { Provider } from 'react-redux'
import { createWeb3ReactRoot, Web3ReactProvider } from '@web3-react/core'
import store from 'state'
import ThemeProvider from 'theme'
import Home from "views/Home";
import './App.less';
import Header from "./components/Header";
import Discord from './views/Discord'
import MiningPool from './views/MiningPool'
import MiningPoolDetail from './views/MiningPoolDetail'
import Miner from './views/Miner'
import MinerDetail from 'views/Miner/MinerDetail';
import MinerAccountHistory from 'views/Miner/MinerAccountHistory';
import DataStore from 'views/DataStore';
import { NetworkContextName } from './constants'
import getLibrary from './utils/getLibrary'
import Web3ReactManager from 'components/Web3ReactManager'

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName)
if (!!window.ethereum) {
  window.ethereum.autoRefreshOnNetworkChange = false
}

function App() {
  return (
    <StrictMode>
      <Web3ReactProvider getLibrary={getLibrary}>
        <Web3ProviderNetwork getLibrary={getLibrary}>
          <Suspense fallback={null}>
            <Provider store={store}>
              <ThemeProvider>
                  <HashRouter>
                    <Header />
                    <Web3ReactManager>
                      <Switch>
                        <Route component={MiningPool} exact path="/" />
                        <Route component={MiningPoolDetail} exact path="/miningpool/:productId" />
                        <Route component={Miner} exact path="/miner/:address" />
                        <Route component={MinerDetail} exact path="/miner/:address/:productId" />
                        <Route component={MinerAccountHistory} exact path="/miner-account-history/:address" />
                        <Route component={MinerAccountHistory} exact path="/miner-account-history/:address/:productId" />
                        <Route component={DataStore} exact path="/datastore" />
                        <Route component={Discord} exact path="/discord/:discordId" />
                      </Switch>
                    </Web3ReactManager>
                  </HashRouter>
              </ThemeProvider>
            </Provider>
          </Suspense>
        </Web3ProviderNetwork>
      </Web3ReactProvider>
    </StrictMode>
  );
}

export default App;
